<template>
  <div class="container" v-cloak>
    <div class="layout-top u-flex-col">
      <!-- 极融花卡 -->
      <ul class="member-title u-flex">
        <li class="pr">好</li>
        <li class="pr">借</li>
        <li class="pr">分</li>
        <li class="pr">期</li>
        <li class="pr">有</li>
        <li class="pr">财</li>
        <li class="pr">卡</li>
      </ul>

      <!-- 吃喝玩乐尽在卡 -->
      <img src="../member/img/img_tips.png" />

      <p class="monthly-tips">每月可享20项权益，价值610元，一单回本</p>
    </div>

    <!-- 滑动区域 -->
    <div class="swipe-con" :class="swipeIndex === 1 ? 'translate-x' : ''">
      <van-swipe
        :loop="false"
        :show-indicators="false"
        @change="handleSwipeChange"
      >
        <!-- 极融花卡 -->
        <van-swipe-item
          class="swipe-item"
          :class="swipeIndex === 1 ? 'pg-top' : ''"
        >
          <div slot="default" class="swipe-item-div pr">
            <img class="drill pa" src="../member/img/icon_drill.png" />
            <div class="u-flex-col">
              <img class="icon-youcai" src="../member/img/icon_youcai.png" />
              <p class="equity">预计可省1280元</p>
              <p class="equity">可享16+项权益，价值582元</p>
            </div>

            <!-- 按钮 -->
            <van-button
              type="primary"
              block
              color="linear-gradient(90deg,#fee2b2, #e1b687)"
              round
              @click="handleOpenMember"
            >
              <h3 slot="default" class="btn-slot-default">
                <small>限时</small>19.9元/月开通
              </h3>
            </van-button>

            <!-- 阅读协议 -->
            <p class="agreement">
              <template v-if="openMemberStatus">
                请阅读
                <span>《会员服务协议》</span>
                <span>《自动续费服务协议》</span>
              </template>
              <template v-else> 将于2022年10月22日过期 </template>
            </p>
          </div>
        </van-swipe-item>

        <!-- 极融花卡PLUS -->
        <van-swipe-item
          class="swipe-item"
          :class="swipeIndex === 0 ? 'pg-top' : ''"
        >
          <div slot="default" class="swipe-item-div pr">
            <img class="drill pa" src="../member/img/icon_drill_plus.png" />
            <div class="u-flex-col">
              <img
                class="icon-youcai icon-youcai-plus"
                src="../member/img/icon_youcai_plus.png"
              />
              <p class="equity">预计可省2280元</p>
              <p class="equity">可享80项权益，价值680元</p>
            </div>

            <!-- 按钮 -->
            <van-button
              type="primary"
              block
              color="linear-gradient(90deg,#fee2b2, #e1b687)"
              round
              @click="handleOpenMember"
            >
              <h3 slot="default" class="btn-slot-default">
                <small>限时</small>26.8元/月开通
              </h3>
            </van-button>

            <!-- 阅读协议 -->
            <p class="agreement">
              请阅读
              <span>《会员服务协议》</span>
              <span>《自动续费服务协议》</span>
            </p>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>

    <!-- 内容区域 -->
    <div class="u-flex-col content-box pr">
      <img src="../member/img/img_circular_mask.png" />
      <div class="content-box-div">
        <!-- 当前有多少人开通 -->
        <div class="u-flex u-row-between many-people-open-con">
          <div class="u-flex">
            <img
              class="icon-avater"
              src="../../assets/images/interactivePro_logo.png"
            />
            <img
              class="icon-avater"
              src="../../assets/images/icon_check_markMax.png"
            />
            <img class="icon-avater" src="../../assets/images/icon_lx.png" />
            <p class="popele">当前已有<span>208,829</span>人开通</p>
          </div>
          <div class="subscription-benefits">订阅权益领取通知</div>
        </div>

        <!-- 热门权益 -->
        <card
          title="热门权益"
          subTitle="每月可享"
          subInfoTitle="每月可享4选1，开卡第8天领首次"
          tagTitle="30"
          tagInfo="预估价值"
        >
          <div class="u-flex goods-con">
            <goods-item
              v-for="(item, idx) in goods"
              :key="idx"
              class="u-flex-1"
              :goodsImg="item.img"
              :goodsTitle="item.title"
              :goodsPrice="item.price"
            />
          </div>
        </card>

        <!-- 代金券 -->
        <card
          title="代金券"
          subTitle="每月可享"
          tagTitle="20"
          tagInfo="预估价值"
        >
          <div class="u-flex u-row-between coupon-con">
            <div class="u-flex-1 u-flex u-row-between">
              <h5 class="coupon-amount"><span>￥</span>20</h5>
              <div class="coupon-info">
                <h5>肯德基代金券</h5>
                <p>开卡第18日领首次</p>
              </div>
            </div>
            <div class="u-flex-1 u-flex u-row-between">
              <h5 class="coupon-amount"><span>￥</span>20</h5>
              <div class="coupon-info">
                <h5>滴滴出行打车券</h5>
                <p>开卡第18日领首次</p>
              </div>
            </div>
          </div>
        </card>

        <!-- 贷款无忧 -->
        <card title="贷款无忧">
          <div class="about-loans u-flex u-row-between u-text-right">
            <span>&nbsp;</span>
            <div class="u-text-center">
              <h4>每月可享</h4>
              <p>30天免息券+万元额度提升</p>
            </div>
          </div>
        </card>

        <!-- Plus额外独享权益 -->
        <div class="youcai-plus-con" v-show="swipeIndex === 1">
          <!-- title -->
          <div class="u-flex u-row-center youcai-plus-con-title">
            <img src="./img/icon_plus_line.png" />
            <p>Plus额外独享权益</p>
            <img src="./img/icon_plus_line.png" />
          </div>

          <!-- 天天秒抢 -->
          <div class="plus-item">
            <div class="u-flex plus-item-title">
              <h5>天天秒抢</h5>
              <span>送亲朋赠好友，最高折扣72%</span>
            </div>
            <div class="plus-item-card u-flex">
              <div class="u-flex-1 u-flex-col card-left">
                <div class="card-item max-card-item u-flex-col pr">
                  <img
                    src="../../assets/images/logo.png"
                    class="card-item-img"
                  />
                  <h5 class="card-item-title card-item-title-max">
                    优酷视频会员季卡
                  </h5>
                  <p class="card-item-info">
                    <small>￥</small>21.5<small>可得</small>
                  </p>
                  <p class="pa tips">价值78元</p>
                </div>
                <div class="card-item u-flex-col pr">
                  <h5 class="card-item-title card-item-title-max">
                    芒果TV视频会员月卡
                  </h5>
                  <p class="card-item-info">
                    <small>￥</small>16.8<small>可得</small>
                  </p>
                  <p class="pa tips">价值30元</p>
                </div>
              </div>
              <div class="u-flex-1 u-flex-col card-right">
                <div class="card-item u-flex-col pr">
                  <h5 class="card-item-title card-item-title-max">
                    爱奇艺视频会员月卡
                  </h5>
                  <p class="card-item-info">
                    <small>￥</small>16.8<small>可得</small>
                  </p>
                  <p class="pa tips">价值30元</p>
                </div>
                <div class="card-item card-item-center u-flex-col pr">
                  <h5 class="card-item-title card-item-title-max">
                    腾讯视频会员月卡
                  </h5>
                  <p class="card-item-info">
                    <small>￥</small>16.8<small>可得</small>
                  </p>
                  <p class="pa tips">价值30元</p>
                </div>
                <div class="card-item u-flex-col pr">
                  <h5 class="card-item-title card-item-title-max">
                    优酷视频会员月卡
                  </h5>
                  <p class="card-item-info">
                    <small>￥</small>16.8<small>可得</small>
                  </p>
                  <p class="pa tips">价值30元</p>
                </div>
              </div>
            </div>
          </div>

          <!-- 在线点单最低5折 -->
          <div class="plus-item">
            <div class="u-flex plus-item-title">
              <h5>在线点单最低5折</h5>
              <span>不限次</span>
            </div>
            <van-grid :border="false" class="zxdd">
              <van-grid-item v-for="(i, idx) in zxdds" :key="idx">
                <van-image
                  :src="i.img"
                  round
                  width="0.96rem"
                  height="0.96rem"
                />
                <p class="zxdd-p">{{ i.title }}</p>
                <van-tag
                  color="linear-gradient(90deg,#f4dcc3, #e4b294)"
                  text-color="#7C4326"
                >
                  {{ `${i.discount}折` }}
                </van-tag>
              </van-grid-item>
            </van-grid>
          </div>

          <!-- 音乐视听 -->
          <div class="plus-item">
            <div class="u-flex plus-item-title">
              <h5>音乐视听</h5>
              <span>最高折扣99%</span>
            </div>
            <van-grid :border="false" class="yyst" :column-num="2" :gutter="9">
              <van-grid-item
                v-for="(i, idx) in yysts"
                :key="idx"
                :class="idx > 1 ? 'style-mt' : ''"
              >
                <van-image
                  :src="i.img"
                  width="0.84rem"
                  height="0.84rem"
                  class="pa"
                />
                <p class="yyst-p">{{ i.title }}</p>
                <h4 class="yyst-h">
                  <small>￥</small>
                  <span>{{ i.discount }}</span>
                  <small>可得</small>
                </h4>
                <p class="pa tips">价值30元</p>
              </van-grid-item>
            </van-grid>
          </div>

          <!-- 红包卡券 -->
          <div class="plus-item">
            <div class="u-flex plus-item-title">
              <h5>红包卡券</h5>
              <span>最高折扣99%</span>
            </div>
            <van-grid :border="false" class="hbkq" :column-num="2" :gutter="9">
              <van-grid-item v-for="(i, idx) in hbkqs" :key="idx">
                <van-image :src="i.img" width="100%" height="2.76rem" />
                <p class="hbkq-p">{{ i.title }}</p>
                <h4 class="hbkq-h">
                  <small>￥</small>
                  <span>{{ i.discount }}</span>
                  <small>可得</small>
                </h4>
              </van-grid-item>
            </van-grid>
          </div>
        </div>

        <!-- 吃喝玩乐 -->
        <card title="吃喝玩乐" tagTitle="500" tagInfo="预估价值">
          <van-grid :border="false" class="chwl" :column-num="2" :gutter="9">
            <van-grid-item v-for="(i, idx) in chwls" :key="idx">
              <van-image :src="i.img" width="100%" height="2.76rem" />
              <p class="chwl-p">{{ i.title }}</p>
              <h4 class="hbkq-h pa">{{ i.tip }}</h4>
            </van-grid-item>
          </van-grid>
        </card>
      </div>
    </div>

    <!-- 底部 -->
    <div class="bottom-tips u-text-center" v-show="swipeIndex === 0">
      以上为极融花卡全部16项权益
    </div>
    <div class="bottom-tips-btn u-text-center" v-show="swipeIndex === 1">
      查看极融花卡Plus全部80项权益>>
    </div>

    <!-- 支付状态弹窗 -->
    <van-popup
      v-model="payStatusPop"
      :close-on-click-overlay="false"
      :style="{
        width: '75%',
      }"
      round
    >
      <div class="pay-status-pop u-text-center">
        <template v-if="payResultStatus === 0">
          <h4>确认支付结果</h4>
          <van-button
            class="comfirm-btn-payment-completed"
            block
            color="linear-gradient(to right, #101012, #26252A"
            round
          >
            已完成支付
          </van-button>

          <van-button class="comfirm-btn-unpaid" block round>
            还未支付
          </van-button>
        </template>

        <template v-else-if="payResultStatus === 1">
          <h4>支付结果查询</h4>
          <van-button
            class="comfirm-btn-search"
            loading
            loading-type="spinner"
            plain
          />
        </template>

        <template v-else-if="payResultStatus === 2">
          <h4>支付成功</h4>
          <img
            class="icon-img"
            src="../../assets/images/icon_check_markMax.png"
          />
          <p class="equity-tips">极融花卡开通成功，快去 领取权益吧~</p>
          <p class="equity-term-validity">极融花卡有效期至2022年11月20日</p>
          <van-button
            class="comfirm-btn-ok"
            block
            color="linear-gradient(to right, #101012, #26252A"
            round
          >
            好的
          </van-button>
        </template>

        <template v-else>
          <h4>支付失败</h4>
          <img
            class="icon-img"
            src="../../assets/images/icon_check_errMax.png"
          />
          <p class="equity-tips">请查询支付渠道账单确认 是否支付成功</p>
          <p class="equity-term-validity">如有疑问，请及时联系客服</p>
          <van-button
            class="comfirm-btn-service"
            block
            color="linear-gradient(to right, #101012, #26252A"
            round
          >
            联系客服
          </van-button>

          <p class="comfirm-btn-not-yet">
            <span @click="payStatusPop = false">先不用</span>
          </p>
        </template>
      </div>
    </van-popup>
  </div>
</template>
<script>
import Card from "./components/card";
import GoodsItem from "./components/goodsItem";

export default {
  name: "Member",
  components: {
    Card,
    GoodsItem,
  },
  data() {
    return {
      swipeIndex: 0, // 轮播索引
      openMemberStatus: true, // 是否开通会员
      goods: [
        // 天天秒抢集合
        {
          img: require("../../assets/images/logo.png"),
          title: "优酷会员",
          price: "价值30元/月",
        },
        {
          img: require("../../assets/images/icon_check_markMax.png"),
          title: "腾讯会员",
          price: "价值30元/月",
        },
        {
          img: require("../../assets/images/interactivePro_logo.png"),
          title: "爱奇艺会员",
          price: "价值30元/月",
        },
        {
          img: require("../../assets/images/product_recomed_bg.png"),
          title: "芒果TV会员",
          price: "价值30元/月",
        },
      ],
      zxdds: [
        // 在线点单集合
        {
          img: require("../../assets/images/logo.png"),
          title: "星巴克",
          discount: 7,
        },
        {
          img: require("../../assets/images/icon_check_markMax.png"),
          title: "瑞幸咖啡",
          discount: 7,
        },
        {
          img: require("../../assets/images/interactivePro_logo.png"),
          title: "奈雪&茶",
          discount: 7,
        },
        {
          img: require("../../assets/images/product_recomed_bg.png"),
          title: "肯德基",
          discount: 7,
        },
        {
          img: require("../../assets/images/product_recomed_bg.png"),
          title: "汉堡王",
          discount: 7,
        },
        {
          img: require("../../assets/images/interactivePro_logo.png"),
          title: "必胜客",
          discount: 7,
        },
        {
          img: require("../../assets/images/icon_check_markMax.png"),
          title: "金拱门",
          discount: 7,
        },
        {
          img: require("../../assets/images/logo.png"),
          title: "猫眼电影",
          discount: 7,
        },
      ],
      yysts: [
        // 音乐试听集合
        {
          img: require("../../assets/images/logo.png"),
          title: "网易云黑胶VIP月卡",
          discount: "21.5",
        },
        {
          img: require("../../assets/images/icon_check_markMax.png"),
          title: "QQ音乐会员月卡",
          discount: "21.5",
        },
        {
          img: require("../../assets/images/interactivePro_logo.png"),
          title: "喜马拉雅会员月卡",
          discount: "21.5",
        },
        {
          img: require("../../assets/images/product_recomed_bg.png"),
          title: "爱企查会员年卡",
          discount: "21.5",
        },
      ],
      hbkqs: [
        // 红包卡券集合
        {
          img: require("../../assets/images/red_envelopes_bg.png"),
          title: "星巴克36元券包",
          discount: "1",
        },
        {
          img: require("../../assets/images/red_envelopes_downicon.png"),
          title: "顺丰同城60元券包",
          discount: "0.01",
        },
        {
          img: require("../../assets/images/red_envelopes_tl4_sbg.png"),
          title: "京东超市10元京东E卡",
          discount: "9.78",
        },
        {
          img: require("../../assets/images/weitu.png"),
          title: "美团吧10元红包",
          discount: "7.05",
        },
      ],
      chwls: [
        // 红包卡券集合
        {
          img: require("../../assets/images/red_envelopes_bg.png"),
          title: "瑞幸咖啡46折优惠",
          tip: "每周可享",
        },
        {
          img: require("../../assets/images/red_envelopes_downicon.png"),
          title: "星巴克7折优惠",
          tip: "每周可享",
        },
        {
          img: require("../../assets/images/red_envelopes_tl4_sbg.png"),
          title: "肯德基5折券",
          tip: "每月可享",
        },
        {
          img: require("../../assets/images/weitu.png"),
          title: "汉堡王脆鸡堡四件套5折",
          tip: "每周可享",
        },
        {
          img: require("../../assets/images/red_envelopes_bg.png"),
          title: "美团外卖券7折",
          tip: "每周可享",
        },
        {
          img: require("../../assets/images/red_envelopes_downicon.png"),
          title: "饿了么会月卡6折",
          tip: "每月可享",
        },
      ],
      payStatusPop: false, // 支付状态弹窗
      payResultStatus: 3,
    };
  },
  created() {},
  methods: {
    // 监听轮播滑动
    handleSwipeChange(index) {
      this.swipeIndex = index;
    },

    // 监听极融花卡按钮点击
    handleOpenMember() {
      console.log(123);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  background: linear-gradient(
    135deg,
    #ffd1a6,
    #f6ead9 57%,
    #f9e2c9 76%,
    #f6e9d6 100%
  );
  padding-bottom: 0.4rem;
  overflow: auto;

  .layout-top {
    padding: 0.36rem 0.4rem;

    .member-title {
      font-size: 0.32rem;
      font-family: HiraginoSansGB, HiraginoSansGB-Regular;
      font-weight: normal;
      color: #1f2121;
      letter-spacing: 0.5rem;

      li {
        width: 0.45rem;
        height: 0.48rem;
        line-height: 0.48rem;
        margin-right: 0.1rem;

        &::after {
          position: absolute;
          content: " ";
          width: 0.02rem;
          height: 73%;
          right: 0.02rem;
          top: 0.06rem;
          background: rgba(31, 33, 33, 0.24);
        }

        &:last-child::after {
          display: none;
        }
      }

      & + img {
        width: 6.08rem;
        margin: 0.24rem 0;
      }
    }

    p.monthly-tips {
      font-size: 0.26rem;
      font-family: HiraginoSansGB, HiraginoSansGB-Regular;
      font-weight: normal;
      color: rgba(102, 52, 9, 0.5);
    }
  }

  .swipe-con {
    padding: 0 0.4rem;

    /deep/ {
      .van-swipe__track {
        width: 11.36rem !important;
      }
    }

    &.translate-x {
      /deep/ {
        .van-swipe__track {
          transform: translateX(-4.7rem) !important;
        }
      }
    }

    .swipe-item {
      width: 5.6rem !important;
      height: 4.36rem;
      margin-right: 0.16rem;
      transition: padding 0.3s ease-in-out;

      &:last-child {
        margin-right: 0;
      }

      &.pg-top {
        padding-top: 0.3rem;
        transition: padding 0.3s ease-in-out;
      }

      > div {
        width: 100%;
        height: 100%;
        background: linear-gradient(135deg, #101012 1%, #26252a 52%, #131217);
        border-radius: 0.24rem;
      }

      .swipe-item-div {
        padding: 0.4rem 0.32rem 0;

        .icon-youcai {
          width: 1.14rem;
          margin-bottom: 0.2rem;
          &.icon-youcai-plus {
            width: 2.08rem;
          }
        }

        .equity {
          font-size: 0.24rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: normal;
          color: rgba(252, 222, 182, 0.5);
          line-height: 0.33rem;
          letter-spacing: -0.004rem;
        }

        .drill {
          width: 2rem;
          top: -0.12rem;
          right: 0;
        }

        .van-button {
          margin: 0.4rem 0 0.16rem;

          .btn-slot-default {
            font-size: 0.32rem;
            font-family: PingFangSC, PingFangSC-Semibold;
            font-weight: bold;
            color: #663409;
            > small {
              font-size: 0.24rem;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: normal;
              color: rgba(102, 52, 9, 0.8);
              margin-right: 0.04rem;
            }
          }
        }

        .agreement {
          font-size: 0.22rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: normal;
          color: rgba(252, 222, 182, 0.5);
          line-height: 0.32rem;
          letter-spacing: -0.0036rem;
        }
      }
    }
  }

  .content-box {
    margin-top: -1.1rem;

    > img {
      width: 100%;
    }

    > .content-box-div {
      padding: 0 0.4rem;
      margin-top: -0.35rem;
      background: #f6e9d6;

      .many-people-open-con {
        padding: 0.08rem 0.08rem 0.08rem 0.36rem;
        background: #fefaf5;
        border-radius: 0.4rem;
        font-size: 0.24rem;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: normal;
        color: #663409;

        .icon-avater {
          width: 0.4rem;
          height: 0.4rem;
          border-radius: 50%;
          border: 0.02rem solid #fff;
          margin-left: -0.16rem;
        }

        .popele {
          font-family: PingFangSC, PingFangSC-Regular;
          color: #1f2121;
          > span {
            color: #fb5e1e;
          }
        }

        .subscription-benefits {
          height: 0.64rem;
          line-height: 0.64rem;
          background: #f2c9a8;
          border-radius: 0.4rem;
          padding: 0 0.16rem;
        }
      }

      .goods-con {
        margin-top: 0.22rem;
        padding: 0.22rem;
        background: #fbf5ec;
        border-radius: 0.16rem;
      }

      .coupon-con {
        margin-top: 0.22rem;

        > div {
          background: url("./img/icon_coupon.png") no-repeat center center;
          background-size: 100% 100%;
          height: 1.24rem;
          padding: 0 0.1rem;

          &:first-child {
            margin-right: 0.16rem;
          }
        }

        .coupon-amount {
          font-size: 0.48rem;
          font-family: PingFangSC, PingFangSC-Semibold;
          font-weight: bold;
          color: #e84733;
          margin-right: 0.28rem;

          > span {
            font-size: 0.22rem;
          }
        }

        .coupon-info {
          font-size: 0.24rem;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: normal;
          color: #511d01;

          > p {
            font-size: 0.2rem;
            font-family: PingFangSC, PingFangSC-Regular;
            color: rgba(102, 52, 9, 0.5);
          }
        }
      }

      .about-loans {
        margin-top: 0.22rem;
        padding-right: 0.6rem;
        background: url("./img/img_coupon.png") no-repeat center center;
        background-size: 100% 100%;
        height: 1.2rem;
        font-family: PingFangSC, PingFangSC-Medium;
        font-size: 0.28rem;
        font-weight: normal;
        color: #511d01;

        p {
          color: #e84733;
        }
      }

      .youcai-plus-con {
        margin-top: 0.22rem;
        background: #101012;
        border-radius: 0.16rem;
        padding: 0.36rem 0.3rem 0.4rem;

        .youcai-plus-con-title {
          font-size: 0.32rem;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: normal;
          color: #fcdeb6;

          p {
            margin: 0 0.14rem;
          }

          > img {
            width: 1.48rem;

            &:last-child {
              transform: rotate(180deg);
            }
          }
        }

        .plus-item {
          margin-top: 0.28rem;

          .plus-item-title {
            font-size: 0.32rem;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: normal;
            color: #fcdeb6;
            margin-bottom: 0.24rem;

            > span {
              font-size: 0.24rem;
              font-family: HiraginoSansGB, HiraginoSansGB-Regular;
              color: rgba(252, 222, 182, 0.5);
              margin-left: 0.1rem;
            }
          }

          .plus-item-card {
            .card-item {
              height: 1.2rem;
              border-radius: 0.16rem;
              padding: 0.18rem;
            }

            .card-left {
              margin-right: 0.18rem;

              .card-item {
                background: linear-gradient(135deg, #fa6a02, #ef4a02);

                &.max-card-item {
                  height: 2.58rem;
                  background: linear-gradient(136deg, #26f6ea 2%, #2068fe);
                  margin-bottom: 0.18rem;
                }
              }
            }

            .card-right {
              .card-item:first-child {
                background: linear-gradient(135deg, #07dc02, #07be03);
              }

              .card-item-center {
                margin: 0.18rem 0;
                background: linear-gradient(135deg, #1273fb, #d287ff 100%);
              }

              .card-item:last-child {
                background: linear-gradient(136deg, #26f6ea 2%, #2068fe);
              }
            }

            .card-item-img {
              width: 0.84rem;
              height: 0.84rem;
              margin-bottom: 0.16rem;
            }

            .card-item-title {
              font-size: 0.24rem;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: normal;
              color: #ffffff;

              &.card-item-title-max {
                font-size: 0.28rem;
              }
            }

            .card-item-info {
              font-size: 0.4rem;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: normal;
              text-align: left;
              color: #ffffff;
              margin-top: -0.08rem;

              > small {
                font-size: 0.2rem;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: normal;
                text-align: left;
                color: rgba(255, 255, 255, 0.5);
              }
            }

            .tips {
              right: 0;
              top: -0.14rem;
              background: #fd3a2b;
              border-radius: 2rem 2rem 2rem 0;
              font-size: 0.22rem;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: normal;
              color: #ffffff;
              padding: 0 0.06rem 0 0.12rem;
            }
          }
        }

        .zxdd {
          background: linear-gradient(180deg, #101012, #1d1d1f);
          border-radius: 0.16rem;

          .zxdd-p {
            font-size: 0.26rem;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: normal;
            color: #fff;
            margin: 0.1rem 0 0.05rem;
          }

          /deep/ {
            .van-grid-item__content {
              padding-top: 0;
              padding-bottom: 0.28rem;
              background: transparent;
            }

            .van-tag {
              justify-content: center;
              width: 0.6rem;
              font-size: 0.22rem;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: normal;
            }
          }
        }

        .yyst {
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: normal;
          color: #fff;
          padding-top: 0.24rem;
          padding-left: 0 !important;

          .van-grid-item {
            &:nth-of-type(even) {
              padding-right: 0 !important;
            }
          }

          div.pa {
            top: -0.24rem;
            left: 0.26rem;
            z-index: 2;
          }

          .yyst-p {
            font-size: 0.26rem;
          }

          .yyst-h {
            font-size: 0.4rem;
            margin-top: -0.1rem;
            font-weight: normal;

            & > small {
              font-size: 0.22rem;
              font-family: PingFangSC, PingFangSC-Regular;
              color: rgba(255, 255, 255, 0.5);
            }
          }

          .style-mt {
            margin-top: 0.48rem !important;
          }

          .tips {
            right: 0;
            top: -0.14rem;
            background: #fd3a2b;
            border-radius: 2rem 2rem 2rem 0;
            font-size: 0.22rem;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: normal;
            color: #ffffff;
            padding: 0 0.06rem 0 0.12rem;
            z-index: 2;
          }

          /deep/ {
            .van-grid-item__content {
              position: relative;
              padding-top: 0.75rem;
              padding-bottom: 0.16rem;
              background: #1d1d1f;
              align-items: flex-start;

              &::after {
                position: absolute;
                box-sizing: border-box;
                content: " ";
                pointer-events: none;
                top: -50%;
                right: -50%;
                bottom: -50%;
                left: -50%;
                border: 0.02rem solid rgba(252, 222, 182, 0.3);
                border-radius: 0.16rem;
                transform: scale(0.5);
              }
            }
          }
        }

        .hbkq {
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: normal;
          color: #fff;
          padding-left: 0 !important;

          .van-grid-item {
            &:nth-of-type(even) {
              padding-right: 0 !important;
            }
          }

          div.van-image {
            overflow: hidden;
            border-radius: 0.12rem;
          }

          .hbkq-p {
            margin-top: 0.1rem;
            font-size: 0.26rem;
          }

          .hbkq-h {
            font-size: 0.4rem;
            font-weight: normal;

            & > small {
              font-size: 0.22rem;
              font-family: PingFangSC, PingFangSC-Regular;
              color: rgba(255, 255, 255, 0.5);
            }
          }

          /deep/ {
            .van-grid-item__content {
              position: relative;
              padding: 0.1rem 0.1rem;
              background: #1d1d1f;
              align-items: flex-start;

              &::after {
                position: absolute;
                box-sizing: border-box;
                content: " ";
                pointer-events: none;
                top: -50%;
                right: -50%;
                bottom: -50%;
                left: -50%;
                border: 0.02rem solid rgba(252, 222, 182, 0.3);
                border-radius: 0.16rem;
                transform: scale(0.5);
              }
            }
          }
        }
      }

      .chwl {
        font-size: 0.24rem;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: normal;
        color: #1f2121;
        padding-left: 0 !important;
        margin-top: 0.16rem;

        .van-grid-item {
          border-radius: 0.16rem;
        }

        .van-grid-item {
          &:nth-of-type(even) {
            padding-right: 0 !important;
          }
        }

        div.van-image {
          overflow: hidden;
          border-radius: 0.16rem;
        }

        .chwl-p {
          margin-top: 0.16rem;
          font-size: 0.26rem;
          padding-left: 0.2rem;
        }

        .hbkq-h {
          right: 0;
          top: 0;
          font-size: 0.22rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: normal;
          color: #7c4326;
          background: linear-gradient(90deg, #f4dcc3, #e4b294);
          border-radius: 0 0.12rem 0 0.18rem;
          padding: 0 0.08rem 0 0.16rem;
        }

        /deep/ {
          .van-grid-item__content {
            position: relative;
            padding: 0 0 0.24rem;
            align-items: flex-start;
            border-radius: 0.16rem;

            &::after {
              position: absolute;
              box-sizing: border-box;
              content: " ";
              pointer-events: none;
              top: -50%;
              right: -50%;
              bottom: -50%;
              left: -50%;
              border: 0.02rem solid #e3e3e3;
              border-radius: 0.32rem;
              transform: scale(0.5);
            }
          }
        }
      }
    }
  }

  .bottom-tips {
    padding: 0 0.4rem;
    margin-top: 0.3rem;
    font-size: 0.24rem;
    font-family: HiraginoSansGB, HiraginoSansGB-Regular;
    font-weight: normal;
    color: rgba(102, 52, 9, 0.5);
  }

  .bottom-tips-btn {
    margin: 0.3rem 0.4rem 0;
    height: 0.9rem;
    line-height: 0.9rem;
    background: linear-gradient(90deg, #fee2b2, #e1b687);
    border-radius: 23px;
    font-size: 0.28rem;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: normal;
    color: #663409;
  }

  .pay-status-pop {
    background: linear-gradient(180deg, #fcefdc, #ffffff);
    border-radius: 0.6rem;
    padding: 0.48rem 0.56rem 0.6rem;

    > h4 {
      font-size: 0.32rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: normal;
      color: #1f2121;
    }

    .comfirm-btn-payment-completed {
      margin: 0.36rem auto 0.3rem;

      .van-button__text {
        color: #f3d6b0;
      }
    }

    .comfirm-btn-unpaid {
      border: 1px solid #18171c;
      color: #252429;
    }

    .comfirm-btn-search {
      background: none;
      border: none;
      margin: 0.72rem auto 0.76rem;

      /deep/ {
        .van-loading__spinner {
          width: 1rem !important;
          height: 1rem !important;
        }

        .van-loading__spinner--spinner i::before {
          width: 0.06rem !important;
        }
      }
    }

    .icon-img {
      width: 1.28rem;
      height: 1.28rem;
      margin: 0.38rem 0;
    }

    .equity-tips {
      font-size: 0.36rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: normal;
      color: #1f2121;
    }

    .equity-term-validity {
      font-size: 0.24rem;
      font-family: HiraginoSansGB, HiraginoSansGB-Regular;
      font-weight: normal;
      color: rgba(31, 33, 33, 0.5);
      margin: 0.3rem auto;
    }

    .comfirm-btn-ok {
      .van-button__text {
        color: #f3d6b0;
      }
    }

    .comfirm-btn-service {
      .van-button__text {
        color: #f3d6b0;
      }
    }

    .comfirm-btn-not-yet {
      background: none;
      border: none;
      color: rgba(37, 36, 41, 0.5);
      font-size: 0.28rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: normal;
      margin: 0.3rem auto 0;
    }
  }
}
</style>
