<template>
  <div class="goods-item-con u-flex-col u-text-center" v-bind="$attrs">
    <img :src="$attrs.goodsImg" />
    <p>{{ $attrs.goodsTitle }}</p>
    <span>{{ $attrs.goodsPrice }}</span>
  </div>
</template>
<script>
export default {
  name: "GoodsItem",
  data() {
    return {};
  },
  created() {
    console.log(this.$attrs);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.goods-item-con {
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: normal;

  > img {
    width: 0.84rem;
    height: 0.84rem;
    margin: 0 auto;
  }

  > p {
    margin: 0.06rem 0 0.04rem;
    font-size: 0.24rem;
    font-family: PingFangSC, PingFangSC-Medium;
    color: #511d01;
  }

  > span {
    font-size: 0.2rem;
    color: rgba(102, 52, 9, 0.5);
  }
}
</style>
